import ConfirmModal from '@appComponents/modals/paymentSuccessfullModal/paymentSuccessfullModal';
import SeoHead from '@appComponents/reUsable/SeoHead';
import { __Error, __pageTypeConstant } from '@constants/global.constant';
import { paths } from '@constants/paths.constant';
import HomeController from '@controllers/homeController';
import { newFetauredItemResponse } from '@definations/productList.type';
import { _GetPageType } from '@definations/slug.type';
import {
  GTMHomeScriptForAllStores,
  GTMHomeScriptForCG,
} from '@helpers/common.helper';
import { highLightError } from '@helpers/console.helper';
import { useActions_v2, useTypedSelector_v2 } from '@hooks_v2/index';
import { customerJourney } from '@services/app.service';
import { FetchDataByBrand } from '@services/brand.service';
import { getFeaturedProducts, getPageComponents } from '@services/home.service';
import { FetchPageType } from '@services/slug.service';
import Home from '@templates/Home';
import { _SelectedTab } from '@templates/ProductDetails/productDetailsTypes/storeDetails.res';
import { GetServerSideProps, GetServerSidePropsResult, NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { _globalStore } from 'store.global';

export interface _Slug_CMS_Props {
  page: 'ALL_CMS_PAGES';
  data: {
    components: any;
    featuredItems: { [x: string]: newFetauredItemResponse[] } | [];
  };
  metaData: _GetPageType;
  slug: string;
  html: any;
}

type _HomeProps =
  | _Slug_CMS_Props
  | {
      error: __Error.noPageTypeFound | __Error.storeIdMissing;
    };

const DefaultHomePage: NextPage<_HomeProps> = (props) => {
  const { id: customerId } = useTypedSelector_v2((state) => state.user);
  const { id: storeId } = useTypedSelector_v2((state) => state.store);
  
  const isCaptured = useRef(false);
  const { hideModal } = useActions_v2();
  const [modalopen, setmodalopen] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    hideModal();
  }, []);

  useEffect(() => {
    if (storeId && !isCaptured.current) {
      isCaptured.current = true;
      GTMHomeScriptForAllStores('HomePage', storeId, customerId || 0);
      GTMHomeScriptForCG('HomePage', storeId, customerId || 0);
    }
  }, [storeId, customerId]);

  useEffect(() => {
    if ('data' in props) {
      let featuredProduct =
        props &&
        props.data?.components.find(
          (comp: any) => comp.name == 'Featured Products',
        );
      if (featuredProduct) {
        localStorage.setItem(
          'Featured Products',
          JSON.stringify(featuredProduct?.selectedVal),
        );
      }
    }
  }, [props]);

  useEffect(() => {
    if (
      localStorage.getItem('OrderNumber') &&
      router?.query &&
      router?.query?.OrderNumber === localStorage.getItem('OrderNumber')
    ) {
      setmodalopen(true);
      localStorage.removeItem('OrderNumber');
    }
  }, [router]);

  if ('error' in props) {
    return <>{props.error}</>;
  }
  useEffect(() => {
    const payload = {
      customerId: customerId || 0,
      pageName: 'Homepage',
      pageTitle: router.pathname,
    };
    customerJourney(payload);
  }, []);

  const { metaData, data, html, slug } = props;

  return (
    <>
      <SeoHead
        title={metaData?.meta_Title ? metaData?.meta_Title : 'Home'}
        description={
          metaData?.meta_Description ? metaData?.meta_Description : ''
        }
        keywords={
          metaData?.meta_Keywords
            ? metaData?.meta_Keywords
            : 'Branded Promotional'
        }
      />

      <Home html={html} pageData={data} slug={slug}/>

      {modalopen && <ConfirmModal setmodalopen={setmodalopen} />}
    </>
  );
};

export default DefaultHomePage;

export const getServerSideProps: GetServerSideProps = async (): Promise<
  GetServerSidePropsResult<_HomeProps>
> => {
  let store = {
    storeCode: _globalStore.code,
    storeTypeId: _globalStore.storeTypeId,
    storeId: _globalStore.storeId,
    isAttributeSaparateProduct: _globalStore.isAttributeSaparateProduct,
  };
  if (_globalStore.storeTypeId === 1) {
    return {
      redirect: {
        statusCode: 301,
        destination: paths.HOME,
      },
    };
  }
  // ---------------------------------------------------------------
  if (!_globalStore.showHomePage) {
    return {
      redirect: {
        permanent: true,
        destination: paths.SB_PRODUCT_LISTING,
      },
    };
  }

  if (store.storeId === 0) {
    highLightError({
      error: `No Store found. Can't proceed further`,
      component: 'Home: getServerSideProps',
    });
    return {
      props: { error: __Error.storeIdMissing },
    };
  }

  // ---------------------------------------------------------------
  const pageMetaData: _GetPageType | null = await FetchPageType({
    storeId: store.storeId,
    slug: '/',
  });
  let allFeaturedProductComponentData: any = [];
  let allFeaturedProductComponentBody: any = [];
  let allTabingData: any = [];
  let bodyArr: any = [];
  console.log('pageMetaData', store.storeId, '/');
  if (pageMetaData === null) {
    highLightError({
      error: `No page type found.`,
      component: 'Home: getServerSideProps',
    });
    return {
      props: { error: __Error.noPageTypeFound },
    };
  }

  ////////////////////////////////////////////////
  /////////// Page Type Checks
  ////////////////////////////////////////////////

  if (pageMetaData.type === __pageTypeConstant.notFound) {
    return {
      notFound: true,
    };
  }
  if (pageMetaData.type === __pageTypeConstant.cmsPages) {
    const components = await getPageComponents({
      pageId: pageMetaData.id,
      type: 'cache',
    });

    const featureProducts = await getFeaturedProducts(components);

    const html = HomeController({
      pageData: {
        components: components,
        featuredItems: featureProducts,
      },
    });

    return {
      props: {
        page: 'ALL_CMS_PAGES',
        data: {
          components: components,
          featuredItems: featureProducts as any,
        },
        metaData: pageMetaData,
        slug: '/',
        html,
      },
    };
  }
  return {
    notFound: true,
  };
};
